.container {
  height: 100%;
  overflow: auto;
}

.logs {
  margin: 0;
  list-style: none;
  padding: 0.5rem 1rem;
  font-family: "Fira Code", "Fira Mono", Menlo, Consolas, "DejaVu Sans Mono", monospace;
}

.logs > li {
  padding: 0.25rem;
  border-bottom: 1px solid var(--highlight-color);
  opacity: 0.75;
  display: block;
  line-height: 1;
  animation-iteration-count: initial;
  animation-name: highlight;
  animation-duration: 1s;
}

.logs > li.warn {
  background-color: rgb(240 164 93 / 5%);
}

.logs > li.warn .icon {
  color: var(--color-ansi-bright-yellow);
}

.logs > li.error {
  background-color: rgba(255 97 110 / 5%);
}

.logs > li.error .icon {
  color: var(--color-ansi-bright-red);
}

.icon {
  vertical-align: top;
  margin-right: 0.5rem;
  position: relative;
  top: 2px;
}

@keyframes highlight {
  from {
    background-color: #2c313c;
  }
}
