.container {
  display: flex;
  background-color: var(--bg-color);
  height: calc(100vh - var(--toolbar-height));
}

.resize {
  width: 5px;
  opacity: .5;
  margin-left: -1px;
  position: relative;
  background-color: var(--border-color);
}

.resize:hover {
  opacity: 1;
}