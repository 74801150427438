.container {
  font-size: 0.75rem;
  line-height: 10px;
  padding: 0.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid var(--border-color);
}

.container a {
  color: var(--text-color);
  text-decoration: none;
}

.container a > svg {
  margin: 0;
  vertical-align: text-top;
}

.container button {
  border: 0;
  line-height: 0;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: var(--text-color);
}

.container button > svg {
  margin: 0;
  vertical-align: text-top;
}

.icon {
  vertical-align: text-bottom;
}

.icon,
.container a,
.container select,
.container button {
  margin-left: 0.5rem;
}

.container button:first-child,
.container a:first-child,
.icon:first-child {
  margin-left: 0;
}

.error,
.warning {
  margin-right: 0.25rem;
}

.run {
  color: var(--color-ansi-green);
}

.warning {
  color: var(--color-ansi-yellow);
}

.error {
  color: var(--color-ansi-red);
}

.loading {
  animation-name: spin;
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; 
}

.dropdown {
  position: relative;
  display: inline-block;
  background: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 0;
  outline: none;
  top: -1px;
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}