:root {
  --toolbar-height: 31px;
  --text-color: #abb2bf;
  --bg-color: #1e2227;
  --border-color: #3e4452;
  --highlight-color: #2c313c;
  --color-muted: #6b717d;
  --color-ansi-green: #8cc265;
  --color-ansi-cyan: #42b3c2;
  --color-ansi-blue: #4aa5f0;
  --color-ansi-bright-blue: #4dc4ff;
  --color-ansi-yellow: #d18f52;
  --color-ansi-bright-yellow: #f0a45d;
  --color-ansi-red: #e05561;
  --color-ansi-bright-red: #ff616e;
  --font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color-scheme: dark;
}

*,:before,:after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent
}

html {
  font-size: 16px;
}

body {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  line-height: 1.5;
  color: var(--text-color);
  font-family: var(--font-family);
  background-color: var(--bg-color);
}